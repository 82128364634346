import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAllCharacteristics } from '../../api/characteristics'

export const fetchAllCharacteristicsAsync = createAsyncThunk(
    "characterstics/fetchAllCharacteristicsAsync",
    async (_, { rejectWithValue }) => {
        try {
            const characteristics = await fetchAllCharacteristics();
            return characteristics;
        }
        catch(err){
            rejectWithValue(err);
        }
})

const characteristicSlice = createSlice({
    name: "characterstics",
    initialState: {
      characterstics: [],
      loading: false,
      error: null,
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchAllCharacteristicsAsync.pending, (state) => {
          state.loading = true;
          state.error = false;
        })
        .addCase(fetchAllCharacteristicsAsync.fulfilled, (state, action) => {
          state.loading = false;
          state.characterstics = action.payload;
        })
        .addCase(fetchAllCharacteristicsAsync.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        });
    },
  });

export default characteristicSlice.reducer;