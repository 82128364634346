import { BASE_URL, fetchWithErrorHandling } from "./helper";

export const fetchAllCharacteristics = async () => {
    try {
        const response = await fetchWithErrorHandling(`${BASE_URL}/characteristic`, { method: "GET" });
        if (!response.ok) throw new Error("Failed to fetch categories");
        const data = await response.json();
        return data.data;
    }
    catch (err) {
        throw err;
    }
}