import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUsersApi, updateUserStatusByIdApi } from "../../api/user";

export const fetchUsersAsync = createAsyncThunk(
  "users/fetchUsersAsync",
  async ({ value, status, page, pageSize }, { rejectWithValue }) => {
    try {
      const data = await fetchUsersApi(value, status || "all", page + 1, pageSize);
      return data;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error);
    }
  }
);

export const updateUserStatusByIdAsync = createAsyncThunk(
  "users/updateUserStatusByIdAsync",
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const users = await updateUserStatusByIdApi(id, { status });
      return users;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    totalCount: 0,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetch Users
      .addCase(fetchUsersAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchUsersAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.users;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchUsersAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //update users by id
      .addCase(updateUserStatusByIdAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateUserStatusByIdAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateUserStatusByIdAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default userSlice.reducer;
