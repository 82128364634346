import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchSubCategoriesApi,
  getSubCategoryByIdApi,
  addSubCategoryApi,
  fetchAllSubCategoriesApi,
  uploadImage,
} from "../../api/subCategory";

export const fetchSubCategoriesAsync = createAsyncThunk(
  "subCategories/fetchSubCategoriesAsync",
  async ({ value, status, category, page, pageSize }, { rejectWithValue }) => {
    try {
      const subCategories = await fetchSubCategoriesApi(
        value,
        status || "all",
        category,
        page + 1,
        pageSize
      );
      return subCategories;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAllSubCategoriesAsync = createAsyncThunk(
  "subCategories/fetchAllSubCategoriesAsync",
  async (_, { rejectWithValue }) => {
    try {
      const subCategories = await fetchAllSubCategoriesApi();
      return subCategories;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSubCategoryByIdAsync = createAsyncThunk(
  "subCategories/fetchSubCategoryByIdAsync",
  async (id, { rejectWithValue }) => {
    try {
      const subCategory = await getSubCategoryByIdApi(id);
      return subCategory;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error);
    }
  }
);

export const addSubCategoryAsync = createAsyncThunk(
  "subCategories/addSubCategoryAsync",
  async (data, { rejectWithValue }) => {
    try {
      const subCategory = await addSubCategoryApi(data);
      return subCategory;
    } catch (err) {
      console.error(err);
      return rejectWithValue(err);
    }
  }
);

export const saveSubCategoryImage = createAsyncThunk(
  "subCategories/saveSubCategoryImage",
  async ({ id, image }, { rejectWithValue }) => {
    try {
      const subCateImage = await uploadImage(id, image);
      return subCateImage;
    } catch (err) {
      console.error({ err });
      return rejectWithValue(err);
    }
  }
);

const subCategorySlice = createSlice({
  name: "subCategories",
  initialState: {
    subCategories: [],
    allSubCategories: [],
    currentSubCategory: null,
    loading: false,
    error: null,
    totalCount: 0,
  },
  reducers: {
    resetState: (state) => {
      state.currentSubCategory = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch Categories
      .addCase(fetchSubCategoriesAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchSubCategoriesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.subCategories = action.payload.subCategories;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchSubCategoriesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // fetch all Categories
      .addCase(fetchAllSubCategoriesAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchAllSubCategoriesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.allSubCategories = action.payload;
      })
      .addCase(fetchAllSubCategoriesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // add Category
      .addCase(addSubCategoryAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addSubCategoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.currentSubCategory = action.payload;
      })
      .addCase(addSubCategoryAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // get Category by Id
      .addCase(fetchSubCategoryByIdAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchSubCategoryByIdAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.currentSubCategory = action.payload;
      })
      .addCase(fetchSubCategoryByIdAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //update image by id
      .addCase(saveSubCategoryImage.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(saveSubCategoryImage.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(saveSubCategoryImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetState } = subCategorySlice.actions;
export default subCategorySlice.reducer;
