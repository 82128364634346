import { BASE_URL, fetchWithErrorHandling } from "./helper";

export const fetchUsersApi = async (value, status, page, pageSize) => {
  try {
    let apiUrl = `${BASE_URL}/user?`;
    if (value) apiUrl += `value=${value}&`;
    if (status) apiUrl += `status=${status}&`;
    if (page) apiUrl += `page=${page}&`;
    if (pageSize) apiUrl += `pageSize=${pageSize}`;

    const response = await fetchWithErrorHandling(apiUrl, { method: "GET" });

    if (!response.ok) throw new Error("Failed to fetch Users");
    const data = await response.json();
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const updateUserStatusByIdApi = async (id, status) => {
  try {
    const response = await fetchWithErrorHandling(
      `${BASE_URL}/user/update-status/${id}`,
      {
        method: "PUT",
        body: JSON.stringify(status)
      }
    );

    if (!response.ok) throw new Error("Failed to add product");
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
