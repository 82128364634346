import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Layout from "./views/Layout/Layout";
import LoginPage from "./views/Login";
import ProtectedRoute from "./routes/ProtectedRoute";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7071b2",
    },
    secondary: {
      main: "#4c4d9d",
    },
  },
});

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <Router>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/*"
              element={
                <ProtectedRoute>
                  <Layout />
                </ProtectedRoute>
              }
            ></Route>
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
};

export default App;
