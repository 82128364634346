import { jwtDecode } from "jwt-decode";
export const BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";

export const getToken = () => {
  return sessionStorage.getItem("token");
};

export const decode_token = (token) => {
  const decoded = jwtDecode(token);
  return decoded;
};

export const headers = (isFormData) => {
  const token = getToken();
  const defaultHeaders = {};
  if (token) {
    const decodedToken = decode_token(token);
    if (!decodedToken) throw new Error("Invalid token");
    if (!decodedToken.role || decodedToken.role !== "ADMIN")
      throw new Error(
        "Unauthorized access. Please try with the correct credentials."
      );
    defaultHeaders["Authorization"] = `Bearer ${token}`;
  }
  if (!isFormData) {
    defaultHeaders["Content-Type"] = "application/json";
  }
  return defaultHeaders;
};

export const fetchWithErrorHandling = async (url, options = {}) => {
  try {
    const isFormData = options.body instanceof FormData;
    const response = await fetch(url, {
      ...options,
      headers: headers(isFormData),
    });
    return response;
  } catch (error) {
    console.error("Fetch error: ", error);
    throw error;
  }
};
