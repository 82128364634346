import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "./styles.css";

function Dropzone({
  saveImage,
  currentImages,
  removeImage,
  multiple,
  fileUploadlabel,
}) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      saveImage(acceptedFiles);
    },
    [saveImage]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    accept: "image/*",
  });

  const removeFile = (fileToRemove) => {
    removeImage(fileToRemove);
  };

  const handleImageSrc = (file) => {
    if (file?.preview) return file.preview;
    else return URL.createObjectURL(file);
  };

  return (
    <div className="position-relative">
      <label className="d-flex dropzone-label">
        {!!fileUploadlabel ? fileUploadlabel : "Upload Images"}
      </label>
      <div className="dropzone-container">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />

          <div className="text-center">
            <p className="dropzone-content">
              {!!multiple
                ? "Drag’n’drop some files here, or click to select files"
                : "Drag’n’drop image, or click to select file"}
            </p>
          </div>
        </div>

        <div className="preview-container mt-3">
          {!!currentImages.length &&
            currentImages?.map((file, index) => (
              <div key={index} className="preview-item">
                <img
                  src={handleImageSrc(file)}
                  alt="preview"
                  className="preview-image"
                />
                <button
                  type={"button"}
                  className="remove-button"
                  onClick={() => removeFile(file)}
                >
                  ×
                </button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Dropzone;
