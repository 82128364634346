import { BASE_URL, fetchWithErrorHandling, decode_token } from "./helper";

export const loginApi = async (credentials) => {
  const response = await fetchWithErrorHandling(`${BASE_URL}/auth/login`, {
    method: "POST",
    body: JSON.stringify(credentials),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Login failed");
  }
  const data = await response.json();
  if (!data.token) throw new Error("No token found");

  const decodedToken = decode_token(data.token);
  if (!decodedToken) throw new Error("Invalid token");

  if (!decodedToken.role || decodedToken.role !== "ADMIN")
    throw new Error(
      "Unauthorized access. Please try with the correct credentials."
    );
  return data;
};

export const logoutApi = async () => {
  const response = await fetchWithErrorHandling(`${BASE_URL}/auth/logout`, {
    method: "GET",
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Logout failed");
  }
  return await response.json();
};

export const accountApi = async () => {
  const response = await fetchWithErrorHandling(`${BASE_URL}/user/account`, {
    method: "GET",
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Failed to load account");
  }
  return await response.json();
};
