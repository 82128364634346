import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Row, Col } from "react-bootstrap";
import {
  InputLabel,
  FormControl,
  OutlinedInput,
  Button as ButtonMui,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Loader from "../../components/Loader";
import withToast from "../../hocs/withToast";
import Dropzone from "../../components/DropZone";
import { BASE_URL } from "../../api/helper";
import { addCategoryAsync } from "../../store/slices/catergorySlice";

const CategoryForm = ({ notify, modalClose, CategoryValidation }) => {
  const dispatch = useDispatch();
  const { loading, currentCategory } = useSelector((state) => state.categories);

  const getImagesWithPreview = () => {
    if (!currentCategory?.id || !currentCategory?.image_url) return 0;
    return [
      {
        image_path: currentCategory?.image_url,
        preview: `${BASE_URL}/doc/i/${currentCategory?.image_url}`,
      },
    ];
  };

  const [categoryImage, setCategoryImage] = useState(
    getImagesWithPreview() || []
  );

  const removeImage = (file) => {
    setCategoryImage(
      categoryImage.filter((ele) => ele.image_path !== file.image_path)
    );
  };

  const formik = useFormik({
    initialValues: {
      name: currentCategory?.name || "",
      id: currentCategory?.id || 0,
      is_main: currentCategory?.is_main || false,
    },
    validationSchema: CategoryValidation,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      values.id && formData.append("id", values.id);
      formData.append("is_main", values.is_main);
      formData.append("is_active", true);
      formData.append("image", categoryImage[0]);
      dispatch(addCategoryAsync(formData))
        .unwrap()
        .then(() => {
          notify("Category Saved", "success");
          if (currentCategory?.id) modalClose();
        })
        .catch((err) => {
          notify(err.message || "Failed to save Category", "error");
        });
    },
  });

  const handleImage = (image) => {
    setCategoryImage([image.at(-1)]);
  };

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        className="d-flex flex-column align-items-center"
      >
        <Row>
          <Col xs={12} className="mt-2">
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-name">Name</InputLabel>
              <OutlinedInput
                id="outlined-adornment-name"
                type={"text"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                name={"name"}
                label="Name"
              />
            </FormControl>
          </Col>
          <Col xs={12} className="text-start mt-2">
            <FormControlLabel
              control={
                <Checkbox
                  name={"is_main"}
                  onChange={formik.handleChange}
                  checked={formik?.values?.is_main}
                />
              }
              label="Is Main"
            />
          </Col>
          <Col xs={12} className="mt-2">
            <Dropzone
              currentImages={categoryImage}
              saveImage={handleImage}
              removeImage={removeImage}
              fileUploadlabel={"Category Image"}
              multiple={false}
            />
          </Col>
          <Col xs={12} className="text-center mt-4">
            <ButtonMui
              variant="contained"
              disabled={!formik.isValid}
              size="large"
              type="submit"
            >
              Save Category
            </ButtonMui>
          </Col>
        </Row>
        <Loader loading={loading} />
      </form>
    </>
  );
};

export default withToast(CategoryForm);
