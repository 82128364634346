import Button from '@mui/material/Button';
import { FaSignOutAlt } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { logoutAsync } from "../store/slices/authSlice";
import { useSelector } from 'react-redux';
import withNavigate from '../hocs/withNavigate';
import withToast from '../hocs/withToast';

const Navbar = ( {navigate, notify} ) => {
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(logoutAsync())
            .unwrap()
            .then(() => {
                navigate("/login");
                notify("Logout successful", "success");
            })
            .catch((err) => {
                console.error("Logout failed", err);
                notify(err.message || "Logout failed", "error");
            });
    };
    return (
        <div className='d-flex align-items-center ms-3' style={{ height: 50 }}>
            <div className='d-flex justify-content-end ps-1'>
                Hello, {user?.first_name} {user?.last_name}!
            </div>
            <div className='position-absolute end-0 me-3'>
                <Button
                    variant="text"
                    onClick={handleLogout}
                    color="secondary"
                >
                    Logout
                    <FaSignOutAlt className="ms-3" />
                </Button>
            </div>
        </div>
    )
}
export default withToast(withNavigate(Navbar));