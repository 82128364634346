import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchCategoriesApi,
  getCategoryByIdApi,
  addCategoryApi,
  fetchAllCategoriesApi,
  uploadImage,
} from "../../api/category";

export const fetchCategoriesAsync = createAsyncThunk(
  "categories/fetchCategoriesAsync",
  async ({ value, status, page, pageSize }, { rejectWithValue }) => {
    try {
      const categories = await fetchCategoriesApi(
        value,
        status || "all",
        page + 1,
        pageSize
      );
      return categories;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAllCategoriesAsync = createAsyncThunk(
  "categories/fetchAllCategoriesAsync",
  async (_, { rejectWithValue }) => {
    try {
      const categories = await fetchAllCategoriesApi();
      return categories;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCategoryByIdAsync = createAsyncThunk(
  "categories/fetchCategoryByIdAsync",
  async (id, { rejectWithValue }) => {
    try {
      const category = await getCategoryByIdApi(id);
      return category;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error);
    }
  }
);

export const addCategoryAsync = createAsyncThunk(
  "categories/addNewCategory",
  async (data, { rejectWithValue }) => {
    try {
      const category = await addCategoryApi(data);
      return category;
    } catch (err) {
      console.error(err);
      return rejectWithValue(err);
    }
  }
);

export const saveCategoryImage = createAsyncThunk(
  "categories/saveCategoryImage",
  async ({ id, image }, { rejectWithValue }) => {
    try {
      const categoryImage = await uploadImage(id, image);
      return categoryImage;
    } catch (err) {
      console.error({ err });
      return rejectWithValue(err);
    }
  }
);

const cartegorySlice = createSlice({
  name: "categories",
  initialState: {
    categories: [],
    allCategories: [],
    currentCategory: null,
    loading: false,
    error: null,
    totalCount: 0,
  },
  reducers: {
    resetState: (state) => {
      state.currentCategory = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch Categories
      .addCase(fetchCategoriesAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchCategoriesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload.categories;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchCategoriesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // fetch all Categories
      .addCase(fetchAllCategoriesAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchAllCategoriesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.allCategories = action.payload;
      })
      .addCase(fetchAllCategoriesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // add Category
      .addCase(addCategoryAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addCategoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.currentCategory = action.payload;
      })
      .addCase(addCategoryAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // get Category by Id
      .addCase(fetchCategoryByIdAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchCategoryByIdAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.currentCategory = action.payload;
      })
      .addCase(fetchCategoryByIdAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //update image by id
      .addCase(saveCategoryImage.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(saveCategoryImage.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(saveCategoryImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetState } = cartegorySlice.actions;
export default cartegorySlice.reducer;
