import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchProductsApi,
  addProductApi,
  getProductByIdApi,
  updateProductById,
  uploadImage,
} from "../../api/product";

export const fetchProductsAsync = createAsyncThunk(
  "products/fetchProductsAsync",
  async (
    { value, status, category_id, subcategory_id, page, pageSize },
    { rejectWithValue }
  ) => {
    try {
      const products = await fetchProductsApi(
        value,
        status || "all",
        category_id,
        subcategory_id,
        page + 1,
        pageSize
      );
      return products;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error);
    }
  }
);

export const addProductAsync = createAsyncThunk(
  "products/addProductAsync",
  async (productData, { rejectWithValue }) => {
    try {
      const addedProduct = await addProductApi(productData); // Call API from api.js
      return addedProduct;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error);
    }
  }
);

export const fetchProductByIdAsync = createAsyncThunk(
  "products/fetchProductByIdAsync",
  async (id, { rejectWithValue }) => {
    try {
      const product = await getProductByIdApi(id);
      return product.data;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error);
    }
  }
);

export const updateProductByIdAsync = createAsyncThunk(
  "products/updateProductByIdAsync",
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const product = await updateProductById(id, { status: status });
      return product.data;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error);
    }
  }
);

export const saveProductImage = createAsyncThunk(
  "products/saveProductImage",
  async ({ id, image }, { rejectWithValue }) => {
    try {
      const productImage = await uploadImage(id, image);
      return productImage;
    } catch (err) {
      console.error({ err });
      return rejectWithValue(err);
    }
  }
);

const productSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
    totalCount: 0,
    currentProduct: null,
    loading: false,
    error: null,
    savedProduct: null,
  },
  reducers: {
    resetState: (state) => {
      state.currentProduct = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchProductsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload.products;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchProductsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //add product
      .addCase(addProductAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addProductAsync.fulfilled, (state, action) => {
        state.products.push(action.payload);
        state.loading = false;
        state.currentProduct = action.payload;
      })
      .addCase(addProductAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //get product by id
      .addCase(fetchProductByIdAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchProductByIdAsync.fulfilled, (state, action) => {
        state.currentProduct = action.payload;
        state.loading = false;
      })
      .addCase(fetchProductByIdAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //update product by id
      .addCase(updateProductByIdAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateProductByIdAsync.fulfilled, (state, action) => {
        state.currentProduct = action.payload;
        state.loading = false;
      })
      .addCase(updateProductByIdAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //update product by id
      .addCase(saveProductImage.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(saveProductImage.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(saveProductImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetState } = productSlice.actions;
export default productSlice.reducer;
