import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";

import {
  fetchUsersAsync,
  updateUserStatusByIdAsync,
} from "../../store/slices/userSlice";

import withToast from "../../hocs/withToast";
import GenericFilter from "../../components/GenericFilter";
import GenericTable from "../../components/GenricTable";
import Loader from "../../components/Loader";
import CustomChip from "../../components/CustomChip";

import { StatusOptions } from "../../utils/constants";

import { MdNotInterested } from "react-icons/md";
import { TiTick } from "react-icons/ti";

const columns = [
  { id: "name", label: "Name", minWidth: 100, align: "left" },
  { id: "email", label: "Email", minWidth: 150, align: "left" },
  { id: "role", label: "Role", minWidth: 100, align: "center" },
  {
    id: "mobile",
    label: "Mobile",
    minWidth: 170,
    align: "left",
  },
  {
    id: "is_active",
    label: "Status",
    minWidth: 100,
    align: "center",
  },
  {
    id: "is_verified",
    label: "Verified",
    minWidth: 100,
    align: "center",
  },
  {
    id: "action",
    label: "Actions",
    minWidth: 40,
    align: "center",
  },
];

const Users = ({ notify }) => {
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [lastListLoadTime, setLastListLoadTime] = useState(new Date());
  const { users, totalCount, loading } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(
      fetchUsersAsync({
        value: filterValue,
        status: statusFilter,
        page,
        pageSize,
      })
    );
  }, [filterValue, statusFilter, page, pageSize, lastListLoadTime]);

  const updatePageSetings = (_page, _rowsPerPage) => {
    if (_page !== page) setPage(_page);
    if (_rowsPerPage !== pageSize) setPageSize(_rowsPerPage);
  };

  const renderActionButtons = (id, status) => {
    return (
      <Button
        aria-label="status-update"
        title={status ? "Inactivate" : "Activate"}
        onClick={() => handleStatusChange({ id: id, status: !status })}
      >
        {status ? (
          <MdNotInterested fontSize="x-large" color="red" />
        ) : (
          <TiTick fontSize="x-large" color="primary" />
        )}
      </Button>
    );
  };

  const handleStatusChange = (data) => {
    dispatch(updateUserStatusByIdAsync(data))
      .unwrap()
      .then((_) => {
        notify("Update user successfully", "success");
        setLastListLoadTime(new Date());
      })
      .catch((err) => {
        console.error(err);
        notify("Something went wrong", "error");
      });
  };

  const rowsWithAction = (rows) => {
    return rows.map((e) => ({
      ...e,
      is_active: (
        <CustomChip
          label={e.is_active ? "Active" : "Inactive"}
          color={e.is_active ? "success" : "error"}
          className={"w-100"}
        />
      ),
      is_verified: (
        <CustomChip
          label={e.is_verified ? "Verified" : "Pending"}
          color={e.is_verified ? "success" : "warning"}
          className={"w-100"}
        />
      ),
      action: renderActionButtons(e.id, e.is_active),
    }));
  };

  return (
    <div>
      <h3>User Management</h3>
      <GenericFilter
        statusLabel={"Status..."}
        searchCb={(e) => setFilterValue(e.target.value)}
        dropdownCb={(e) => setStatusFilter(e)}
        includeStatusFilter={true}
      />
      <GenericTable
        columns={columns}
        rows={rowsWithAction(users)}
        cb={updatePageSetings}
        rowCount={totalCount}
      />
      <Loader loading={loading} />
    </div>
  );
};

export default withToast(Users);
