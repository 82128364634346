import React from 'react';
import { toast } from 'react-toastify';

// Higher-Order Component for toast
const withToast = (WrappedComponent) => {
  return (props) => {
    const notify = (message, type = 'default') => {
      toast[type](message);
    };
    
    return <WrappedComponent {...props} notify={notify} />;
  };
};

export default withToast;