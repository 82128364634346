import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  InputLabel,
  FormControl,
  OutlinedInput,
  Button as ButtonMui,
  Select,
  MenuItem,
} from "@mui/material";
import Loader from "../../../components/Loader";
import withToast from "../../../hocs/withToast";
import Dropzone from "../../../components/DropZone";
import { BASE_URL } from "../../../api/helper";
import { addSubCategoryAsync } from "../../../store/slices/subCategorySlice";

const SubCategoryForm = ({ notify, modalClose, CategoryValidation }) => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.categories);
  const { loading, currentSubCategory } = useSelector(
    (state) => state.subCategories
  );

  const getImagesWithPreview = () => {
    if (!currentSubCategory?.id || !currentSubCategory?.image_url) return 0;
    return [
      {
        image_path: currentSubCategory?.image_url,
        preview: `${BASE_URL}/doc/i/${currentSubCategory?.image_url}`,
      },
    ];
  };

  const [subCategoryImage, setSubCategoryImage] = useState(
    getImagesWithPreview() || []
  );

  const removeImage = (file) => {
    setSubCategoryImage(
      subCategoryImage.filter((ele) => ele.image_path !== file.image_path)
    );
  };

  const formik = useFormik({
    initialValues: {
      name: currentSubCategory?.name || "",
      id: currentSubCategory?.id || 0,
      category_id: currentSubCategory?.category_id || "",
    },
    validationSchema: CategoryValidation,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("category_id", values.category_id);
      values.id && formData.append("id", values.id);
      formData.append("is_main", true);
      formData.append("is_active", true);
      formData.append("image", subCategoryImage[0]);
      dispatch(addSubCategoryAsync(formData))
        .unwrap()
        .then((data) => {
          notify("Sub Category Saved", "success");
          if (currentSubCategory?.id) modalClose();
        })
        .catch((err) => {
          console.error({ err });
          notify(err.message || "Failed to save Sub-Category", "error");
        });
    },
  });

  const handleImage = (images) => {
    setSubCategoryImage([images.at(-1)]);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="d-flex flex-column align-items-center"
    >
      <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-name">Name</InputLabel>
        <OutlinedInput
          id="outlined-adornment-name"
          type={"text"}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          name={"name"}
          label="Name"
        />
      </FormControl>
      <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-size">
          Choose Category
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formik.values.category_id}
          label="Choose Category"
          name={"category_id"}
          onChange={(op) =>
            formik.setFieldValue("category_id", op.target.value)
          }
        >
          {categories.map((ele, index) => (
            <MenuItem value={ele.id} key={`categories_idx${index}`}>
              {ele.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="mt-2">
        <Dropzone
          currentImages={subCategoryImage}
          saveImage={handleImage}
          removeImage={removeImage}
          fileUploadlabel={"Sub-category Image"}
          multiple={false}
        />
      </div>
      <div className="mt-2 text-center">
        <ButtonMui
          variant="contained"
          disabled={!formik.isValid}
          size="large"
          type="submit"
        >
          Save Sub Category
        </ButtonMui>
      </div>
      <Loader loading={loading} />
    </form>
  );
};

export default withToast(SubCategoryForm);
