import Chip from "@mui/material/Chip";
export default function CustomChip({ label, color, className = null }) {
  return (
    <Chip
      label={label}
      className={className}
      color={color}
      variant="outlined"
    />
  );
}
