import * as Yup from 'yup';

export const ProductValidation = Yup.object({
    name: Yup.string()
        .required('Email is Required'),
    description: Yup.string()
        .required('Email is Required'),
    size: Yup.string()
        .required('Password is Required'),
    category_id: Yup.string()
        .required('Category is Required'),
    subcategory_id: Yup.string()
        .required('Sub Category is Required'),
});
