import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { loginApi, logoutApi, accountApi } from "../../api/auth";

export const loginAsync = createAsyncThunk(
  "auth/loginAsync",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await loginApi(credentials);
      return response;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error);
    }
  }
);

export const logoutAsync = createAsyncThunk(
  "auth/logoutAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await logoutApi();
      return response;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error);
    }
  }
);

export const accountAsync = createAsyncThunk(
  "auth/accountAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await accountApi();
      return response;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  isAuthenticated: !!sessionStorage.getItem("token"),
  token: sessionStorage.getItem("token") || null,
  user: { name: "John Doe" },
  error: null,
  loading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    //only synchronous calls
  },
  extraReducers: (builder) => {
    builder
      //login cases
      .addCase(loginAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.details;
        state.token = action.payload.token;
        state.isAuthenticated = true;
        sessionStorage.setItem("token", action.payload.token);
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //logout cases
      .addCase(logoutAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutAsync.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
        state.isAuthenticated = false;
        sessionStorage.removeItem("token");
      })
      .addCase(logoutAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        sessionStorage.removeItem("token");
      })

      //account cases
      .addCase(accountAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(accountAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.data;
      })
      .addCase(accountAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.isAuthenticated = false;
        state.user = null;
        state.token = null;
        sessionStorage.removeItem("token");
      });
  },
});

export default authSlice.reducer;